import React, { useState } from 'react';
import { Box, Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography, TextField, MenuItem, Grid } from '@mui/material';
import { Helmet } from 'react-helmet';
import { request } from '../_libs/api';

const BookNow = () => {
  const [open, setOpen] = useState(true); // Modal will open automatically when the page loads
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [selectedTime, setSelectedTime] = useState('');
  const [seats, setPeople] = useState<number>(1);
  const [notes, setNotes] = useState('');
  const [name, setBookingName] = useState('');
  const [phone, setContactNumber] = useState('');
  const [email, setEmailAddress] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [error, setError] = useState('');

  const handleClose = () => setOpen(false);

  const handleBooking = async (event: React.FormEvent) => {
    event.preventDefault();

    // Validation: Check that required fields are filled
    if (!name || !phone || !selectedDate || !selectedTime || seats <= 0) {
      setError('Please fill in all required fields.');
      return;
    }

    const timeEval = selectedTime.split(':')
    const datetime = new Date(selectedDate)
    datetime.setHours(parseInt(timeEval[0]))
    datetime.setMinutes(parseInt(timeEval[1]))
    const reqPayload = {
      name,
      phone,
      email,
      datetime: datetime.getTime(),
      seats,
      notes,
    }
    await request('/webform/booking', reqPayload)
      .then(() => {
        setSelectedDate('');
        setSelectedTime('');
        setPeople(0);
        setNotes('');
        setBookingName('');
        setContactNumber('');
        setEmailAddress('');
        setShowConfirmation(false);
      })
      .catch((error) => {
        console.error('BOOKING validation error', error, error.subError);
      });

    setError(''); // Clear error if validation passes
    setShowConfirmation(true);
    setTimeout(() => setShowConfirmation(false), 5000); // Hide confirmation after 5 seconds
    handleClose(); // Close the modal
  };

  const generateTimeSlots = () => {
    const slots = [];
    for (let i = 12; i <= 22.5; i += 0.5) {
      const hours = Math.floor(i);
      const minutes = i % 1 === 0.5 ? '30' : '00';
      slots.push(`${hours}:${minutes}`);
    }
    return slots;
  };

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>

      <Box>
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm" scroll="paper">
          <DialogTitle>Booking Details</DialogTitle>
          <DialogContent dividers>
            {/* Display error message if fields are not filled */}
            {error && (
              <Typography color="error" variant="body1" sx={{ mb: 2 }}>
                {error}
              </Typography>
            )}

            {/* Booking Name */}
            <TextField
              label="Booking Name *"
              fullWidth
              value={name}
              onChange={(e) => setBookingName(e.target.value)}
              sx={{ mb: 2 }}
              required
            />

            {/* Contact Number */}
            <TextField
              label="Contact Number *"
              fullWidth
              value={phone}
              onChange={(e) => setContactNumber(e.target.value)}
              sx={{ mb: 2 }}
              required
            />

            {/* Email Address */}
            <TextField
              label="Email Address (Optional)"
              fullWidth
              value={email}
              onChange={(e) => setEmailAddress(e.target.value)}
              sx={{ mb: 2 }}
            />

            {/* Select Date */}
            <TextField
              label="Select Date *"
              type="date"
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={selectedDate || ''}
              onChange={(e) => setSelectedDate(e.target.value)}
              sx={{ mb: 2 }}
              required
            />

            {/* Select Time */}
            <TextField
              select
              label="Select Time *"
              fullWidth
              value={selectedTime}
              onChange={(e) => setSelectedTime(e.target.value)}
              sx={{ mt: 2 }}
              required
            >
              {generateTimeSlots().map((slot) => (
                <MenuItem key={slot} value={slot}>
                  {slot}
                </MenuItem>
              ))}
            </TextField>

            {/* Number of People */}
            <TextField
              type="number"
              label="Number of People *"
              fullWidth
              value={seats}
              onChange={(e) => setPeople(parseInt(e.target.value, 10))}
              inputProps={{ min: 1, max: 10 }}
              sx={{ mt: 2 }}
              required
            />

            {/* Notes */}
            <TextField
              label="Notes (Optional)"
              fullWidth
              multiline
              rows={3}
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              sx={{ mt: 2 }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleBooking} variant="contained" sx={{ bgcolor: '#A52A2A', color: '#fff' }}>
              Submit Booking
            </Button>
            <Button onClick={handleClose} variant="outlined">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>

        {showConfirmation && (
          <Box
            sx={{
              mt: 3,
              bgcolor: 'rgba(255, 255, 255, 0.8)',
              p: 2,
              borderRadius: 1,
              boxShadow: 3,
            }}
          >
            <Typography variant="h6" color="green">
              Booking request has been sent.
              {email ? ' You will receive a confirmation via email shortly.' : ' You will be contacted via your phone number for confirmation.'}
            </Typography>
          </Box>
        )}
      </Box>
    </>
  );
};

export default BookNow;
